import { useMutation, useQuery } from '@apollo/client';
import { useMediaQuery } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { useSnackbar } from 'notistack';
import React, { useContext } from 'react';

import { GET_WALLETS_LITE, SYNC_API } from '../../apollo/typeDefs';
import IcActionsTrade from '../../media/icons/ic-actions-trade.svg';
import { showDefaultErrorSnackbarMessage } from '../../utils/showDefaultErrorSnackbarMessage';
import { UsersContext } from '../hooks/useUser';
import IconButton from '../IconButton';
import AccentButton from '../structure/AccentButton';
import { ERROR_SYNC_API } from '@cr/common/src/config/error-codes';

const SyncWalletsButton = () => {
  const { t } = useTranslation('common');
  const { enqueueSnackbar } = useSnackbar();
  const isMd = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const { runningJob, setRunningJob } = useContext(UsersContext);

  const [syncAPI, { loading: syncAPILoading }] = useMutation(SYNC_API, {
    onError: () =>
      showDefaultErrorSnackbarMessage({
        t,
        enqueueSnackbar,
        code: ERROR_SYNC_API,
      }),
  });

  const { data = { wallets: [] } } = useQuery(GET_WALLETS_LITE);
  const isSyncDisabled = !data?.wallets?.some(
    (wallet) => Boolean(wallet.api.key) && wallet.api.state !== 'OFFLINE'
  );

  const startSync = async () => {
    setRunningJob('job-syncing');
    await syncAPI({
      update: (cache, { data: { syncApi } }) => {
        if (!syncApi) return;
        for (const wallet of data.wallets) {
          if (!wallet.api?.key) continue;
          cache.modify({
            id: cache.identify(wallet),
            fields: { api: (cached) => ({ ...cached, state: 'SYNCING' }) },
          });
        }
      },
    });
  };

  if (isMd)
    return (
      <IconButton
        key={1}
        color="primary"
        onClick={startSync}
        loading={syncAPILoading}
      >
        <IcActionsTrade style={{ stroke: '#ffffff' }} />
      </IconButton>
    );

  return (
    <AccentButton
      isLoadingButton={true}
      startIcon={<IcActionsTrade style={{ stroke: '#ffffff' }} />}
      loadingPosition="start"
      disabled={isSyncDisabled}
      loading={syncAPILoading || !!runningJob}
      onClick={startSync}
    >
      {t('Wallets synchronisieren')}
    </AccentButton>
  );
};

export default SyncWalletsButton;
