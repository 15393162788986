import { Divider, Drawer, Skeleton, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Head from 'next/head';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';

import IcMediaCollection from '../media/icons/ic-media-collection.svg';
import SyncWalletsButton from './buttons/SyncWalletsButton';
import Footer from './Footer';
import AccentButton from './structure/AccentButton';

const Section = (props) => {
  const { center = false, header, metaTitle } = props;

  return (
    <>
      {(header || metaTitle) && (
        <Head>
          <title>{header ? header : metaTitle}</title>
        </Head>
      )}

      {/* Sets container to the spare view: view - header - wiggle-room (no space for the footer, because the scrollbar should be always visible...) */}
      <Box sx={{ flex: 'auto', minHeight: 'calc(100vh - 6rem - 0.5rem)' }}>
        {center ? <CenterSection {...props} /> : <LeftSection {...props} />}
      </Box>

      <Footer />
    </>
  );
};

export default Section;

function CenterSection({ header, children }) {
  return (
    <Container maxWidth="lg" style={{ display: 'grid', placeItems: 'center' }}>
      <div style={{ maxWidth: 420 }}>
        <Grid
          container
          direction="column"
          gap="1rem"
          justifyContent={'center'}
          alignItems={'center'}
          sx={{ mb: 4 }}
        >
          <Grid item sx={{ mb: 1, mt: 9 }}>
            <Typography
              variant="h1"
              style={{ fontSize: 36, textAlign: 'center' }}
            >
              {header}
            </Typography>
          </Grid>

          {children}
        </Grid>
      </div>
    </Container>
  );
}

function LeftSection({
  header,
  cHeader,
  displayWalletSync = false,
  renderPrimaryAction,
  secondaryActions,
  secondaryButtonLabel,
  children,
}) {
  const [menuOpen, setMenuOpen] = useState(false);

  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <Container maxWidth="lg">
      {(header || cHeader) && (
        <Box sx={{ pt: isDesktop ? 6.5 : 1, margin: 0 }}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              {cHeader ? (
                cHeader
              ) : (
                <Typography variant="h1">
                  {header === '' ? (
                    <Skeleton variant="text" animation={false} width={250} />
                  ) : (
                    header
                  )}
                </Typography>
              )}
            </Grid>

            {((!isMobile && renderPrimaryAction) || displayWalletSync) && (
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={2}
                >
                  {!isMobile && renderPrimaryAction && (
                    <Grid item>{renderPrimaryAction({})}</Grid>
                  )}
                  {displayWalletSync && (
                    <Grid item>
                      <SyncWalletsButton />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Box>
      )}

      <Box sx={{ pt: isMobile ? 3 : 4, pb: 10 }}>
        <Grid container spacing={isMobile ? 3 : 5}>
          {!isMobile && secondaryActions && (
            <Grid item xs={12}>
              <Grid
                container
                spacing={2}
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                {secondaryActions.map((action, index) => {
                  if (Array.isArray(action))
                    return (
                      <Grid item key={index} {...action[1]}>
                        {action[0]}
                      </Grid>
                    );
                  return (
                    <Grid item key={index}>
                      {action}
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          )}

          {isMobile && (
            <>
              {renderPrimaryAction && (
                <Grid item xs={12}>
                  <Grid container spacing={2} direction="row">
                    <Grid item xs={secondaryActions && 6}>
                      {renderPrimaryAction({ fullWidth: true })}
                    </Grid>
                    {secondaryActions && (
                      <Grid item xs={6}>
                        <AccentButton
                          fullWidth
                          color="secondary"
                          startIcon={<IcMediaCollection />}
                          onClick={() => setMenuOpen(true)}
                          sx={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                          }}
                        >
                          {secondaryButtonLabel}
                        </AccentButton>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              )}

              {secondaryActions && (
                <Drawer
                  anchor="bottom"
                  open={menuOpen}
                  onClose={() => setMenuOpen(false)}
                >
                  <Grid
                    container
                    direction="column"
                    alignItems="stretch"
                    spacing={1}
                  >
                    {secondaryActions.map((action, index) => (
                      <Grid item key={index}>
                        {Array.isArray(action) ? action[0] : action}
                      </Grid>
                    ))}
                  </Grid>
                </Drawer>
              )}

              <Divider
                sx={{
                  width: 'calc(100% + 16px)',
                  marginTop: 4,
                  marginBottom: 1,
                }}
              />
            </>
          )}

          {children}
        </Grid>
      </Box>
    </Container>
  );
}
